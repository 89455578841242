import "./src/styles/global.scss"
import "./src/styles/style.scss"
import React from "react"
import MessengerChat from "./src/components/messengerChat"

export const wrapRootElement = ({ element, props }) => {
  return (
    <div>
      {element}
      <MessengerChat />
    </div>
  )
}
